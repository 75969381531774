.deferred-media{
  display: block;
  position: relative;
}
.deferred-media__poster{
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--color-background);
  border: none;
  cursor: pointer;
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
}
.deferred-media[loaded]>.deferred-media__poster{
  z-index: 0;
  visibility: hidden;
}
.deferred-media iframe{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
  background-color: var(--color-background);
  z-index: 1;
}
.deferred-media:not([loaded]) template {
  z-index: -1;
}
.deferred-media__poster-button{
  --background: var(--color-body-background);
  --radius-button: 50%;
  --border: 1px solid var(--color-lines-borders);
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: var(--background);
  height: 60px;
  width: 60px;
  z-index: 1;
}
.deferred-media__poster-button.thumbnail__badge{
  height: 30px;
  width: 30px;
  pointer-events: none;
}
.deferred-media__poster-button svg{
  width: 20px;
  height: 20px;
}
product-modal .deferred-media__poster,
media-gallery .deferred-media__poster{
  background-color: transparent;
  overflow: hidden;
}
product-modal .deferred-media__poster img,
media-gallery .deferred-media__poster img {
  width: auto;
  max-width: 100%;
  height: 100%;
}
product-modal .deferred-media__poster-button,
media-gallery .deferred-media__poster-button {
  left: 50%;
  top: 50%;
  transform: translate(-50%,-50%) scale(1);
}